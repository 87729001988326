import { useEffect, useRef, useState } from "react";
import QRCode from "qrcode";
import spacetime from "spacetime";

const secondsInADay = 24 * 60 * 60

export const App = () => {
    let params = new URLSearchParams(document.location.search)
    let seconds = params.get("s")

    return <div className="w-screen h-[90vh] flex flex-col justify-center items-center">
        {seconds ? <TimeDisplay timestampSeconds={seconds} /> : <TimeQR />}
    </div>
}

const formatSeconds = (seconds) => {
    const hrs = Math.floor(seconds / 60 / 60)
    const mins = Math.floor((seconds - hrs * 60 * 60) / 60)
    const secs = seconds - hrs * 60 * 60 - mins * 60

    return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`
}

const TimeDisplay = (props) => {
    return (
        <span className="text-4xl">
            {props.timestampSeconds ? formatSeconds(props.timestampSeconds) : "An error occurred."}
        </span>)

}

const useToggleFullscreen = () => {
    const [fullscreen, setFullscreen] = useState(false)
    useEffect(() => {
        if (document.documentElement.requestFullscreen) {
            if (fullscreen) {
                document.documentElement.requestFullscreen()
            } else {
                document.exitFullscreen().catch(() => { })
            }
        }
    }, [fullscreen])

    return () => setFullscreen((fullscreen) => !fullscreen)
}

const TimeQR = () => {
    const [open, setOpen] = useState(false)
    const [parameters, setParameters] = useState({ set: false, start: 0, current: 0, end: 0 })
    const toggleFullscreen = useToggleFullscreen()

    // Time keeping
    useEffect(() => {
        const interval = setInterval(() => {
            // increment current seconds
            setParameters((params) => ({ ...params, current: params.current + 1 }))
            setParameters((params) => {
                if (params.current > secondsInADay) {
                    return { ...params, set: false }
                } else {
                    return { ...params, current: params.current + 1 }
                }
            })
        }, 1000);
        return () => { clearInterval(interval) }
    }, [])

    // Status and QR code keeping
    const [imageData, setImageData] = useState("")
    useEffect(() => {
        // Check art work open status
        setOpen(parameters.current >= parameters.start && parameters.current <= parameters.end)
        // Update QR code
        const duration = parameters.current - parameters.start
        QRCode.toDataURL(`${window.location.href}?s=${duration}`)
            .then((imageData) => {
                setImageData(imageData)
            })
    }, [parameters])

    return <>
        {parameters.set ?
            (open ?
                <img
                    src={imageData}
                    onClick={toggleFullscreen}
                    className="object-contain h-3/4 aspect-square nearest"
                />
                :
                <span>outside the parameters of the artwork</span>
            )
            :
            <StartParameterForm onSetParameters={setParameters} />
        }
    </>

}

const timeStringToSeconds = (time) => {
    const [hour, minute] = time.split(":")
    const hourSeconds = hour * 60 * 60
    const minuteSeconds = minute * 60
    const totalSeconds = hourSeconds + minuteSeconds
    return Number(totalSeconds)
}

const StartParameterForm = ({ onSetParameters }) => {
    const startTimeRef = useRef()
    const currentTimeRef = useRef()
    const endTimeRef = useRef()

    const onStart = () => {
        if (onSetParameters) {
            onSetParameters({
                set: true,
                start: timeStringToSeconds(startTimeRef.current.value),
                current: timeStringToSeconds(currentTimeRef.current.value),
                end: timeStringToSeconds(endTimeRef.current.value)
            })
        }
    }

    return <form className="grid grid-cols-2 gap-4">
        <label>Start Time:</label>
        <input ref={startTimeRef} type="time" defaultValue="10:00" />
        <label>Current Time:</label>
        <input ref={currentTimeRef} type="time" defaultValue={spacetime.now().format("time-24")} />
        <label>End Time:</label>
        <input ref={endTimeRef} type="time" defaultValue="16:00" />
        <input
            className="border-2 border-black hover:border-gray-500 hover:bg-slate-100 rounded p-2 cursor-pointer"
            type="button"
            value="start"
            onClick={onStart}
        />
    </form>
}